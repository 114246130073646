var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"}},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headersDetalhe,"endpoint":_vm.endpointDetalhe,"sortBy":"barcode","sortDesc":false,"pdf":"","csv":"","excel":""},scopedSlots:_vm._u([{key:"available",fn:function(ref){
var col = ref.col;
return [(col)?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v(" Sim ")]):_c('v-chip',{attrs:{"color":"error","small":""}},[_vm._v(" Não ")])]}},{key:"price",fn:function(ref){
var col = ref.col;
return [_vm._v(" R$ "+_vm._s(_vm.formatReal(col))+" ")]}},{key:"found",fn:function(ref){
var col = ref.col;
return [(col)?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v(" Sim ")]):_c('v-chip',{attrs:{"color":"error","small":""}},[_vm._v(" Não ")])]}},{key:"success",fn:function(ref){
var col = ref.col;
return [(col)?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v(" Sim ")]):_c('v-chip',{attrs:{"color":"error","small":""}},[_vm._v(" Não ")])]}}])})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
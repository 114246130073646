<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <DataTable
        :headers="headersDetalhe"
        :endpoint="endpointDetalhe"
        sortBy="barcode"
        :sortDesc="false"
        ref="datatable"
        pdf
        csv
        excel
      >
        <template v-slot:available="{ col }">
          <v-chip v-if="col" color="success" small>
            Sim
          </v-chip>
          <v-chip v-else color="error" small>
            Não
          </v-chip>
        </template>
        <template v-slot:price="{ col }"> R$ {{ formatReal(col) }} </template>
        <template v-slot:found="{ col }">
          <v-chip v-if="col" color="success" small>
            Sim
          </v-chip>
          <v-chip v-else color="error" small>
            Não
          </v-chip>
        </template>
        <template v-slot:success="{ col }">
          <v-chip v-if="col" color="success" small>
            Sim
          </v-chip>
          <v-chip v-else color="error" small>
            Não
          </v-chip>
        </template>
      </DataTable>
    </Panel>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'CargaPrecoDetalhe',
  components: {
    DataTable,
    Panel,
    MarketingModules
  },
  data: () => ({
    idProcessamento: '',
    headersDetalhe: [
      { text: 'Cód. barras', value: 'barcode' },
      { text: 'Cód. interno', value: 'internalcode' },
      { text: 'Disponível Venda', value: 'available', align: 'center' },
      { text: 'Preço', value: 'price', align: 'center' },
      { text: 'Produto encontrado', value: 'found', align: 'center' },
      { text: 'Preço atualizado', value: 'success', align: 'center' }
    ],
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpointDetalhe() {
      return `/api/v2/integracao-carga-preco/fornecedor/${this.getFornecedorId}/${this.idProcessamento}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {
    this.idProcessamento = this.$route.params.idprocessamento
  },
  methods: {
    formatReal(v = '0') {
      return `${parseFloat(v).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`
    }
  }
}
</script>

<style lang="scss" scoped></style>
